import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { MailOutlined, PhoneOutlined, WhatsApp } from "@material-ui/icons"
import { graphql } from "gatsby"

const ContactUsPage = ({ data }: { data: any }) => {
  const theme = useTheme()
  const classes = makeStyles({
    paper: {
      padding: theme.spacing(4),
    },
    contactList: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      // height: "100%",
      paddingBottom: theme.spacing(4),

      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    map: {
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2),
      },
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const { email, number, whatsappLink } = data.site.siteMetadata

  return (
    <Layout>
      <Seo title="Contact Us" />
      <Container maxWidth="md" disableGutters={isSmallScreen}>
        <Paper className={classes.paper} elevation={isSmallScreen ? 0 : 1}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                Contact Us
              </Typography>
              <Divider className={classes.divider} variant="middle" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className={classes.contactList}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PhoneOutlined />}
                  href={`tel:${number}`}
                  className="contactPageLink"
                >
                  Call
                </Button>
                {/* <Button variant="contained" color="primary" startIcon={<DescriptionOutlined />}>
                Form
              </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<MailOutlined />}
                  href={`mailto:${email}`}
                  className="contactPageLink"
                >
                  Email
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<WhatsApp />}
                  href={whatsappLink}
                  className="contactPageLink"
                >
                  Whatsapp
                </Button>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Paper className={classes.map} elevation={0}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448.11779009736097!2d28.0761124862057!3d-26.03544319441858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9572374468dd67%3A0xf9e7422abdf2bf9e!2s75%20Edison%20Cres%2C%20Sunninghill%2C%20Sandton%2C%202191!5e0!3m2!1sen!2sza!4v1638560257102!5m2!1sen!2sza"
                  width="100%"
                  height="360"
                  style={{ border: 0 }}
                  // allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Layout>
  )
}

export default ContactUsPage

export const contactQuery = graphql`
  query ContactPage {
    site {
      siteMetadata {
        email
        number
        whatsappLink
      }
    }
  }
`
